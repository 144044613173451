import { useState, useEffect } from 'react';
export default function PlaceMap(quakeprops) {

  const [mapID, setMapID] = useState("map_" + quakeprops.id);
  
  
  useEffect(() => {
    const script = document.createElement('script');
    //script.text = "createMap('map_" + quakeprops.id + "');"
    script.text = "createMap2('map_" + quakeprops.id + "', " + quakeprops.location[0] + ", " + quakeprops.location[1] + ");";
    console.log(script.text);
  
    //script.src = "https://use.typekit.net/foobar.js";
    console.log("Creating child map");
    //script.src = "http://localhost:3000/js/createMap.js";
    script.async = false;
    
    document.body.appendChild(script);
  
    //return () => {
    //  document.body.removeChild(script);
    //}
  }, []);
  

  return (
    <>
    <div id={mapID} style={{height: "180px", borderRadius: 15}}>PlaceMap</div>
    </>
  )
}
