import { useFetch } from "../hooks/useFetch";
import QuakeCard from './QuakeCard';

export default function QuakeList(props) {

    console.log(props);

    let starttime = props.year + "-01-01";
    let endtime = props.year + "-12-31";

    //const [url, setUrl] = useState("https://earthquake.usgs.gov/fdsnws/event/1/query?format=geojson&minlatitude=49.781264&minlongitude=-11.030273&maxlatitude=61.058285&maxlongitude=2.153320&minmagnitude=1&starttime=2008-01-01&endtime=2008-12-31&orderby=time-asc");
    //let [url, setUrl] = useState("https://earthquake.usgs.gov/fdsnws/event/1/query?format=geojson&minlatitude=49.781264&minlongitude=-11.030273&maxlatitude=61.058285&maxlongitude=2.153320&minmagnitude=1&starttime=" + starttime + "&endtime=" + endtime +"&orderby=time-asc");
    let url = "https://earthquake.usgs.gov/fdsnws/event/1/query?format=geojson&minlatitude=49.781264&minlongitude=-11.030273&maxlatitude=61.058285&maxlongitude=2.153320&minmagnitude=1&starttime=" + starttime + "&endtime=" + endtime +"&orderby=time-asc";
    //const [url, setUrl] = useState("http://localhost:8000/features");

    console.log(url);

    let { data: quakes, loading, error } = useFetch(url);

      

    return (

    <div>
        { loading && <p>Loading Earthquakes...</p>}

        { error && <p>{error}</p>}

        { 
        quakes && quakes.features.map((quake) => (
        //quakes && quakes.map((quake) => (

                <QuakeCard key={quake.id} quake={quake} ></QuakeCard>
                            
            
        )) }
    </div>
    
    )
}
