import { useState } from 'react';
import PlaceMap from './PlaceMap';

export default function QuakeCard(quakeObj) {

    function convertT(unix_timestamp)
    {
        var date = new Date(unix_timestamp).toLocaleDateString("en-GB")
        return date;
    }

    function trimTitle(title)
    {
        if (title.indexOf(", United Kingdom"))
        {
            return title.substring(0, title.indexOf(", United Kingdom"));    
        }
        return title;
    }

    console.log(quakeObj);

    const [quake, setQuake] = useState(quakeObj.quake);
    
    return (
    
    
    <div className={String(quake.properties.place).search("France") >=0 ? "hidden" : "card m" + String(Math.floor(quake.properties.mag))} key={quake.id}>
        <p className="time">{convertT(quake.properties.time)}</p>
        <p className="title"><a href={quake.properties.url} target="_blank" title="USGS Info">{trimTitle(quake.properties.title)}</a></p>
        {/* <p className="detailUrl">{quake.properties.url}</p> */}
        <PlaceMap id={quake.id} location={quake.geometry.coordinates} />

        <p className="location">{quake.geometry.coordinates[1]}&deg;N,&nbsp;
            {String(quake.geometry.coordinates[0]).search("-") >= 0 ? String(quake.geometry.coordinates[0]).substring(1) : quake.geometry.coordinates[0]} 
            &deg;
            {String(quake.geometry.coordinates[0]).search("-") >= 0 ? "W" : "E"} 
        </p>
    </div>
  )
}
