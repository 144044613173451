import './App.css';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';

function App() {

  return (
    <>



    <div className="App">
      <Header />
      <Main />
      <Footer />
      {/* <div id="map" style={{height: "180px"}}></div>  */}
    </div>
    </>
  );
}

export default App;
