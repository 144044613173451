import { useState } from "react"
import { isCompositeComponent } from "react-dom/test-utils";
import QuakeList from "./QuakeList"

export default function Main() {

  const [year, setYear] = useState(2023);

  function handleChange(e) {
    //console.log(e.target.value);
    setYear(e.target.value);
  }

  return (
    <>
    <h2>UK Earthquakes in {year}</h2>
    <div className="selectYear">
      Select year:&nbsp;&nbsp;
      <select onChange={handleChange}>
        <option>2023</option>
        <option>2022</option>
        <option>2021</option>
        <option>2020</option>
        <option>2019</option>
        <option>2018</option>
        <option>2017</option>
        <option>2016</option>
        <option>2015</option>
        <option>2014</option>
        <option>2013</option>
        <option>2012</option>
        <option>2011</option>
        <option>2010</option>
        <option>2009</option>
        <option>2008</option>
      </select>
    </div>
    <div className="main">
        <QuakeList year={year}/>
    </div>
    </>
  )
}
