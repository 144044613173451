import { useEffect, useState } from "react";

export const useFetch = (url) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            setLoading(true);
            console.log("Querying " + url);
            try{
                const response = await fetch(url);
                if (!response.ok)
                {
                    throw new Error(response.statusText);
                }
                //console.log("Response is: ")
                //console.log(response);
                const result = await response.json();
                setLoading(false)
                //console.log(result);
                setData(result);
                setError("");
            } catch (error) {
                setLoading(false);
                setError(error.message)
            }
            
        }
        fetchData();
        return () => controller.abort();
    }, [url]);

  return { data, loading, error }
}